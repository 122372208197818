import { Component, OnInit } from '@angular/core';
import { strings } from '@britishfencing/shared/assets';

@Component({
  selector: 'britishfencing-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  content:any;
  constructor() {
    console.log('any strings here?',strings())
    this.content = strings();
  }

}
