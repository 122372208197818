<section class="max-width">
  <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onSubmit()" >
    <img class="login-logo" src="./assets/images/bflogowhite.svg">
    <p class="form-error-heading" *ngIf="submitError">
      {{submitError}}
    </p>

    <p class="form-success-heading" *ngIf="loginSuccess">
      {{loginSuccess}}
    </p>

    <input formControlName="email" id="email" name="email" type="email" [placeholder]="content.emailPlaceholder">
    <input formControlName="password" id="password" name="password" type="password" [placeholder]="content.passwordPlaceholder">
    <button type="submit"  id="login" class="button-link">
        {{loginButtonText}}
    </button>
    <a routerLink="" id="back" class="button-link">{{content.backLink}}</a>
    <a routerLink="/forgotten-password" class="forgotten-password">{{content.forgottenPasswordLink}}</a>
  </form>
</section>
