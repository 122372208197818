import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { EfDataService } from '@britishfencing/shared/ef-data-access';
import { strings } from '@britishfencing/shared/assets';

@Component({
  selector: 'britishfencing-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss'],
})
export class ForgottenPasswordComponent {
  resetForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
  });
  submitting = false;
  resetUrl = "";
  resetsuccess = "";
  submitError = "";
  content:any;

  constructor(private efDataService: EfDataService)
  {
    this.content = strings();
  }

  onSubmit() {
    this.resetsuccess = "";
    this.submitError = "";
    if (this.resetForm.valid) {
      this.submitting = true;
      this.efDataService.getResetPassword(this.resetForm.value.email)
      .subscribe({
        next: (message) => {
          this.submitting = false;
          this.resetsuccess = message;
        },
        error: (err) => {
          this.submitting = false;
          this.submitError = err.error.message;
        }
      });

    }

    return false;
  }

}
