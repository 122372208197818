import { GameComponent } from './../game/game.component';
import { LandingComponent } from './../landing/landing.component';
import { ListcoachComponent } from './../listcoach/listcoach.component';
import { LoginComponent } from './../login/login.component';
import { PageNotFoundComponent } from './../page-not-found/page-not-found.component';
import { Routes } from "@angular/router";
import { AuthGuardService, NoAuthGuardService } from '../auth/auth-guard.service';
import { ForgottenPasswordComponent } from '../forgotten-password/forgotten-password.component';

export const routes: Routes = [
  {
    path: "",
    component: LandingComponent,
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [NoAuthGuardService]
  },
  {
    path: "free-games",
    component: ListcoachComponent,
    data: {free: true}
  },
  {
    path: "forgotten-password",
    component: ForgottenPasswordComponent,
    canActivate: [NoAuthGuardService]
  },
  {
    path: "coach-games",
    component: ListcoachComponent,
    canActivate: [AuthGuardService],
    data: {free: false}
  },
  {
    path: "free-games/:id",
    component: GameComponent
  },
  {
    path: "coach-games/:id",
    component: GameComponent
  },
  {
    path: '404', 
    component: PageNotFoundComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];
