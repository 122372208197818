<section class="max-width">
  <form [formGroup]="resetForm" class="reset-form" (ngSubmit)="onSubmit()">
    <p>To request a password reset, submit your email. If your address is registered, you will be sent a reset link</p>
    <p class="form-error-heading" *ngIf="submitError">
      {{submitError}}
    </p>
    <p class="form-success-heading" *ngIf="resetsuccess">
      {{resetsuccess}}
    </p>

    <!-- <img class="login-logo" src="./assets/images/bflogowhite.svg"> -->
    <input formControlName="email" id="email" name="email" type="email" placeholder="Email">
    <button type="submit" id="reset" class="button-link">
      <span *ngIf="!submitting">
        {{content.resetButtonText}}
        </span>
        <span class="button-caption-container" *ngIf="submitting">
          {{content.loading}}
        </span>
    </button>
    <a routerLink="/login" id="back" class="button-link">Back to login</a>
  </form>
</section>
