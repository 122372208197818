import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {
    this.auth.initLogin()

  }

  canActivate(): Observable<boolean> {
    return this.auth.loggedIn.pipe(
      map(loggedin => {
        console.log('canactive yes?', loggedin)
        return loggedin
       } ),
      tap((loggedin) => {
        console.log('am i logged in, yes?',loggedin)
        if(!loggedin)
        {
          this.router.navigate(['login']);
        }
    })
    );
  }
}

@Injectable()
export class NoAuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {
    this.auth.initLogin()

  }

  //for any pages you should not see if you're logged in, e.g. the /login page
  canActivate(): Observable<boolean> {
    return this.auth.loggedIn.pipe(
      map(loggedin => {
        console.log(loggedin,"map loggedin")
        return !loggedin
      } ),
      tap((notloggedin) => {
        console.log('am i logged in,no?', notloggedin)
        if(!notloggedin)
        {
          this.router.navigate(['coach-games']);
        }
    })
    );
  }
}
