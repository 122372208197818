import { Inject, Injectable } from "@angular/core";
import {
  map,
  Observable,
  of,
  ReplaySubject,
  timer,
  delay,
  catchError,
  throwError,
  tap,
  concatMap,
  retry,
} from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment, AccessToken, User } from "@britishfencing/shared/ef-data-access";
import { AuthCredentialsService } from "./auth-credentials.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _loggedIn: ReplaySubject<boolean> = new ReplaySubject(1);
  public readonly loggedIn: Observable<boolean> = this._loggedIn;

  public userPipe: ReplaySubject<User | null> = new ReplaySubject(1);

  private _user: User | null = null;

  get user(): User | null {
    return this._user;
  }

  constructor(
    private http: HttpClient,
    private authCredentialsService:AuthCredentialsService,
  ) { }

  initLogin() {
    console.log('initLogin')
    const token = this.authCredentialsService.getToken();
    if(!token) {
      this.loginFailure();
      return;
    }
    this.checkLogin().subscribe((isLoggedIn) => {
      console.log('checked login', isLoggedIn)
      if (isLoggedIn) {
        this.loginSuccess();
      } else {
        this.loginFailure();
      }
    });
  }

  login(email: string, password: string, saveToken=false) {
    const url = `${environment.api}${environment.path}${environment.authpath}auth`;

    return this.http.post<AccessToken>(url, { email, password }).pipe(
      tap((token: AccessToken) => {
        if (saveToken && token) {
          console.log('save token on login', token)
          this.authCredentialsService.saveToken(token.data.jwt);
        }
        this.loginSuccess();
        return null;
      }),
      map((_) => null),
      catchError((err) => {
        this.loginFailure();
        if (err instanceof HttpErrorResponse) {
          console.log('ERROR', err)
            return throwError(() => new Error(err.error.data.message));
        }
        return throwError(() => new Error("Sorry, there was an unknown error while attempting to sign in."))
      })
    );
  }

  logout() {
    this.http
      .post(`${environment.api}/logout`, {})
      .pipe(
        retry(3),
        catchError((_) => of(null)),
        tap((_) => this.removeLoginDetails()),
        tap((_) => (window.location.href = `${environment.appBaseUrl}/login/`))
      )
      .subscribe();
  }

  removeLoginDetails() {
    console.log('removeLoginDetails')
    this.authCredentialsService.removeTokens();
    this.setUser(null);
  }

  setUser(user: User | null) {
    this._user = user;
    this.userPipe.next(user);
    console.log('loggedin 1', !!user)
    this._loggedIn.next(!!user);
  }

  checkLogin(): Observable<boolean> {
    // TODO - check authentication state

    return this.getUserProfile().pipe(
      tap((result:any) => {
        console.log('checkLogin success', result)
        this.setUser(result);
      }),
      map((_) => true),
      catchError((err) => {
        console.log('checkLogin error', err)
        this.setUser(null);
        return of(false);
      })
    );
    // return timer(1000).pipe(map((result) => true));
  }

  getUserProfile(): Observable<boolean> {
    const url = `${environment.api}${environment.path}${environment.authpath}auth/validate`;

    return this.http.get(url).pipe(
      map((result:any) => {
        console.log('getUserProfile map', result)
        return result.data.user
      }),
      tap((user:any) => {
        console.log('getUserProfile tab', user)
        if(user) {
          this.setUser(user);
          console.log('loggedin 2', true)
          this._loggedIn.next(true);
        }
        else {
          console.log('loggedin 3', false)
          this._loggedIn.next(false)
        }
      })
    );
  }

  authenticationFailure() {}

  private loginSuccess() {
    console.log('loggedin 4')
    this._loggedIn.next(true);
  }

  private loginFailure() {
    console.log('loggedin 5')
    this._loggedIn.next(false);
  }

}
