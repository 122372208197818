import { Component, OnInit } from '@angular/core';
import { EfDataService, Game } from '@britishfencing/shared/ef-data-access';
import { map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'britishfencing-listcoach',
  templateUrl: './listcoach.component.html',
  styleUrls: ['./listcoach.component.scss'],
})
export class ListcoachComponent implements OnInit {
  items:Game[] = [];
  type = "";
  free = true;

  constructor(private efDataService: EfDataService,
  private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.free = this.activatedRoute.snapshot.data['free'];
    console.log('Free games?', this.free)
    const request = this.free ? this.efDataService.getFreeGameLists() : this.efDataService.getPaidGameLists();
    this.type = this.free ? 'free' : 'coach';
    request.subscribe(games => {
      this.items = games;
    });
  }
}
