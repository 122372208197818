<div [ngClass]="{'modal-container': true, 'app-mode': appMode}">
  <header>
    <h1>{{ modalTitle }}</h1>
    <button (click)="closeModal()">
      <img src="/assets/images/close.svg" />
    </button>
  </header>
  <section>
    <div *ngIf="clickedButton !== 'video'" [innerHtml]="html | safehtml"></div>

    <div *ngIf="clickedButton === 'video'" class="video-container">
      <iframe
        width="560"
        height="315"
        [src]="safeSrc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </section>
</div>
