import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeScript, SafeStyle, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safehtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
