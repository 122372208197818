import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'britishfencing-game-content',
  templateUrl: './game-content.component.html',
  styleUrls: ['./game-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GameContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }
}
