import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { EfDataService } from '@britishfencing/shared/ef-data-access';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import { ListcoachComponent } from './listcoach/listcoach.component';
import { HeaderComponent } from './header/header.component';
import { RoutingModule } from './routing/routing.module';
import { GameComponent } from './game/game.component';
import { GameHeaderComponent } from './game-header/game-header.component';
import { GameContentComponent } from './game-content/game-content.component';
import { GameFactsComponent } from './game-facts/game-facts.component';
import { GameFooterComponent } from './game-footer/game-footer.component';
import { GameModalComponent } from './game-modal/game-modal.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  AuthGuardService,
  NoAuthGuardService,
} from './auth/auth-guard.service';
import { JwtInterceptor } from './auth/ef-httpinterceptor.service';

@NgModule({
  providers: [
    EfDataService,
    AuthGuardService,
    NoAuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  imports: [CommonModule, RoutingModule, ReactiveFormsModule, FormsModule],
  declarations: [
    LandingComponent,
    ListcoachComponent,
    HeaderComponent,
    GameComponent,
    GameHeaderComponent,
    GameContentComponent,
    GameFactsComponent,
    GameFooterComponent,
    GameModalComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    PageNotFoundComponent,
    ForgottenPasswordComponent,
    SafeHtmlPipe,
  ],
})
export class WebUiModule {}
