import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'britishfencing-game-facts',
  templateUrl: './game-facts.component.html',
  styleUrls: ['./game-facts.component.scss'],
})
export class GameFactsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
