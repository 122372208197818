<section class="max-width">
  <div class="coach-access">
    <img class="landing-logo" src="./assets/images/bflogowhite.svg" />
    <a class="italic red button-link" routerLink="login">{{
      content.coachaccess
    }}</a>
  </div>
  <div class="free-games">
    <img class="landing-logo" src="./assets/images/eflogo.svg" />
    <a class="italic blue button-link" routerLink="free-games">{{
      content.freeaccess
    }}</a>
  </div>
  <a
    class="privacy-link"
    target="_blank"
    href="https://www.britishfencing.com/privacy-policy-explore-fencing"
    >Privacy Policy</a
  >
</section>
