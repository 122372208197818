import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'britishfencing-game-modal',
  templateUrl: './game-modal.component.html',
  styleUrls: ['./game-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GameModalComponent implements OnInit {

  @Input() type: any;
  @Input() popupsubject: any;
  @Input() appMode: boolean;
  clickedButton:string;
  modalTitle:string;
  html:string;
  youtubeUrl:string;
  safeSrc: SafeResourceUrl;

  @Input() modalContent:any;

  constructor(private sanitizer: DomSanitizer) {

    this.clickedButton = "";
    this.modalTitle = "";
    this.html = "";
    this.youtubeUrl = "";
    this.safeSrc = "";
    this.appMode = false;
  }

  ngOnInit(): void {
    this.type.subscribe((arg:any) => {
      this.clickedButton = arg;
      if(this.clickedButton == 'facts') {
        this.modalTitle = 'Fun Facts';
      }
      else {
        this.modalTitle = this.clickedButton;
      }
      this.html = this.modalContent != undefined ? this.modalContent[arg] : "";
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+this.html);
    });
  }

  closeModal() {
    this.popupsubject.next();
  }

}
