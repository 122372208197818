import { RouterModule } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { environment } from '@britishfencing/shared/ef-data-access';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { strings } from '@britishfencing/shared/assets';
import { convertHSLToRGBColor } from '@nativescript/core/css/parser';

@Component({
  selector: 'britishfencing-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // providers: [AuthService]
})
export class LoginComponent {

  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required]),
  });
  submitting = false;

  loginSuccess = "";
  submitError = "";
  loginButtonText:string;

  content:any;

  constructor(
      private formBuilder: FormBuilder,
      private authService: AuthService,
      private router: Router
    ) {
      this.content = strings();
      console.log("am i logged in? ",this.authService.loggedIn);
      if(!environment.production) {
        this.loginForm.value.email  = 'something@wearetelescopic.co.uk';
        this.loginForm.value.password = 'password';
      }

      this.loginButtonText = this.content.loginButton;
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  onSubmit() {

    if (this.loginForm.valid) {
      this.submitError = ' ';
      this.submitting = true;
      this.loginButtonText = this.content.loading;
      this.authService
        .login(
            this.loginForm.value.email ? this.loginForm.value.email : "" ,
            this.loginForm.value.password ? this.loginForm.value.password : "",
            true)
        .subscribe({
          next: () => {
            this.resetForm();
            this.loginSuccess = this.content.loginSuccess;

            this.router.navigate(['coach-games']);
            console.log('go to coach??')
          },
          error: (err: string) => {
            this.resetForm();
            console.log(err)
            if(err == "Wrong user credentials.") {
              this.submitError = this.content.wrongCreds;
            }
            else {
              this.submitError = err;
            }
          }
        })
        .add(() => {
          console.log('final complete login')
           this.loginButtonText = this.content.loginButton

        });
    } else {
      console.log(this.content);
      this.submitError = this.content.emailInvalidError;
      this.loginForm.markAllAsTouched();
    }

    return false;
  }

  resetForm() {
    this.submitting = false;
    this.submitError = "";
    // this.resetsuccessmessage = "";
    this.loginSuccess = "";

    this.loginForm.patchValue({
      email: "",
      password: "",
    });

    Object.keys(this.loginForm.controls).forEach((key) => {
      this.loginForm.get(key)?.setErrors(null);
    });

    this.loginForm.markAsPristine();
    this.loginForm.markAsUntouched();
  }

}
