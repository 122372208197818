import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'britishfencing-game-header',
  templateUrl: './game-header.component.html',
  styleUrls: ['./game-header.component.scss'],
})

export class GameHeaderComponent implements OnInit {
  @Input() headerContent:any;
  @Input() headerImage:any;
  @Input() appMode = false;

  constructor() {
    
  }

  ngOnInit(): void {
    console.log('headerimaegh:',this.headerImage)
  }
}
