<britishfencing-header
  [backRoute]="backRoute"
  *ngIf="!appMode"
></britishfencing-header>
<article
  [ngClass]="{ 'app-mode': appMode }"
  [ngStyle]="{ 'background-color': gameBackground }"
  *ngIf="singleGame"
>
  <britishfencing-game-header
    [headerImage]="headerImage"
    [headerContent]="headerContent"
  ></britishfencing-game-header>
  <britishfencing-game-content
    [innerHTML]="gameContent | safehtml"
  ></britishfencing-game-content>
  <britishfencing-game-footer
    [modalContent]="gameModals"
    [appMode]="appMode"
  ></britishfencing-game-footer>
</article>
