import { environment } from './environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, retry, throwError } from 'rxjs';
import { Game } from './model/game';

@Injectable({ providedIn: 'root' })
export class EfDataService {

  gameUrl = ""
  resetUrl = ""

  constructor(private http:HttpClient) {
    this.gameUrl = `${environment.api}${environment.path}${environment.efpath}`
    this.resetUrl = `${environment.api}${environment.path}${environment.efpath}password-reset`
  }

  getResetPassword(emailaddress:any):Observable<any> {
    console.log('reset send', emailaddress)
    return this.http.post<any>(this.resetUrl, { email: emailaddress }).pipe(
      map((res:any) => {
        return res.message
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  getPaidGameLists():Observable<any> {
    const url = `${this.gameUrl}games?free=0`;

    return this.http.get(url).pipe(
      map((res:any) => {
        console.log(res)
        return res.data
      }),
    );
  }

  getFreeGameLists() {
    console.log('--> getfreegamelists');

    const url = `${this.gameUrl}games?free=1`;
    console.log('--> getfreegamelists from', url)

    return this.http.get(url).pipe(
      map((res:any) => {
        return res.data
      })
    );
  }


  getGame(id:number, free:boolean){
    const url = `${this.gameUrl}single-game/${id}?free=${free}`;
    return this.http.get(url).pipe(
      map((res:any) => {
        return res.data
      })
    );
  }


}
