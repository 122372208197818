import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'britishfencing-game-footer',
  templateUrl: './game-footer.component.html',
  styleUrls: ['./game-footer.component.scss'],
})
export class GameFooterComponent implements OnInit {

  showPopup:boolean;
  clickedButton:string;
  subject = new BehaviorSubject<string>('');
  popupsubject = new BehaviorSubject<any>(false);

  @Input() modalContent:any;
  @Input() appMode:boolean;

  constructor() {
    this.showPopup = false;
    this.appMode = false;
    this.clickedButton = '';
  }

  ngOnInit(): void {
    this.popupsubject.subscribe(() => {
      this.showPopup = false
    })
  }

  openPopup(button:any): void {
    if(this.clickedButton !== button) {
      this.showPopup = true;
      this.clickedButton = button
    }
    else {
      this.showPopup = false;
      this.clickedButton = '';
    }
    console.log(this.clickedButton,"clicked button")
    this.subject.next(this.clickedButton)

  }

  returnZero() {
    return 0;
  }


}
