import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthCredentialsService {

  urlToken = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    
    if (this.getToken() == 'unavailable') {
      this.removeTokens();
    }
  }
  saveToken(token: string) {
      localStorage.setItem('ef_token', token || 'unavailable');
  }

  getToken() {
    //first check if we should be using a token from the url
    //http://localhost:4200/coach-games/240?app_mode=true&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjMwNjUwMTksImV4cCI6MTY2MzA2NTA3OSwiZW1haWwiOiJzb21ldGhpbmdAd2VhcmV0ZWxlc2NvcGljLmNvLnVrIiwiaWQiOjh9.H4vnja6QLHxv60L5twsiotTJPo5HunvfKveTZoU-zOY

    this.route.queryParams.subscribe(params => {
      this.urlToken = params['token'];

      if(this.urlToken) {
        console.log('we have a token', window.location.pathname);
        console.log(this.route.snapshot.pathFromRoot)
        this.removeTokens();
        this.saveToken(this.urlToken);

        let queryParams = {};
        const params = (new URL(window.location.href)).searchParams;
        if(params.has('app_mode')) {
          queryParams = {'app_mode': true};
        }
        //remove from current route
        this.router.navigate(
          [window.location.pathname], 
          { 
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: queryParams
          }
        );
        console.log('AFTER REDIRECT')
      }
    });
    return localStorage.getItem('ef_token');
  }

  removeTokens() {
    localStorage.removeItem('ef_token');
  }
}
