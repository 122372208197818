import { environment } from "@britishfencing/shared/ef-data-access";
import {  Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, concatMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthCredentialsService } from './auth-credentials.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  // Don't logout on 401 for these urls (e.g. update password returns 401 if the currentPassword is incorrect )


  constructor(
    private authCredentialsService:AuthCredentialsService,
    private http: HttpClient) {

  }

  private shouldInterceptUrl(
    request: HttpRequest<any>) {

      if (request.url.indexOf(environment.api) !== 0) {
        return false
      }
      else {
        const path = request.url.slice(environment.api.length);
        return environment.noIntercept.indexOf(path) === -1 && path.indexOf('free=true') === -1;
      }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!this.authCredentialsService) {
        return next.handle(request);
      }

     
      
      const token = this.authCredentialsService.getToken();
      console.log('token?', token)

      const shouldAuthenticate = this.shouldInterceptUrl(request);
      if (shouldAuthenticate && token) {
        console.log('INTERCEPT!!!!!!!!')
        const newRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });

        const finish = next.handle(newRequest);

        return finish.pipe(
          catchError((err) => {
            const status = err['status'];
            
            const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
            const requestOptions = { headers: headers };

            console.log('ERROR STATUS',status)
            switch (status) {
              case 403: //unauthorized
              case 400: //expired
                console.log('EXPIRED???', headers)
                console.log(headers)
                this.authCredentialsService.removeTokens();
                return this.http.post(`${environment.api}${environment.path}${environment.authpath}auth/refresh`,{},
                    requestOptions)
                  .pipe(
                    concatMap( (response: any) => {
                      console.log('interceptor, token?')
                      // this.authCredentialsService.saveToken(response.data.jwt);
                      return this.intercept(request, next)
                    })
                  )
              default:
                return throwError(() => err);
            }
          })
        );
      }

      return next.handle(request);
    }
}
