export function strings(): any {
  return {
    "coachaccess": "coach access",
    "freeaccess": "free games",
    "emailLabel": "Email",
    "emailPlaceholder": "Your email",
    "emailRequiredError": "Please enter an email address",
    "emailInvalidError": "Please enter a valid email address",
    "wrongCreds": "Sorry, those credentials are not correct",
    "passwordLabel": "Password",
    "passwordPlaceholder": "Your password",
    "passwordRequiredError": "Please enter your password",
    "loginButton": "Login",
    "forgottenPasswordLink": "Forgotten password? Reset",
    "backLink": "Back",
    "loginSuccess": "Login successful",
    "submitError": "Error logging in",
    "loading": "Loading...",
    "resetButtonText":  "Send reset email"
    };
}

