import { Component, OnInit, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EfDataService } from '@britishfencing/shared/ef-data-access';
import { map } from 'rxjs';

@Component({
  selector: 'britishfencing-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  providers: [EfDataService],
})
export class GameComponent implements OnInit {
  private sub: any;
  gameId: number;
  singleGame: any;
  gameContent: any;
  gameModals: any;
  gameBackground: any;
  headerContent: string;
  headerImage: string;
  freeGame: boolean;
  backRoute: string;
  appMode: boolean;
  appModeValue = '';

  constructor(
    private dataService: EfDataService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.gameId = 0;
    this.headerContent = '';
    this.headerImage = '';
    this.gameBackground = '';
    this.freeGame = false;
    this.backRoute = '/';
    this.appMode = false;
  }

  ngOnInit(): void {
    console.log(
      'game init',
      this.route.url.pipe(map((segments) => segments.join('')))
    );
    this.sub = this.route.params.subscribe((params) => {
      this.gameId = parseInt(params['id']);
      console.log('parameters route', params);
    });

    this.route.url
      .pipe(map((segments) => segments[0].path))
      .subscribe((type) => {
        this.freeGame = type == 'free-games' ? true : false;
      });

    this.route.queryParams.subscribe((params) => {
      console.log('parameters query', params);
      if (!this.freeGame) {
        console.log('COACH GAME----------------');
      }

      this.appMode =
        params['app_mode'] != '' &&
        params['app_mode'] != 'undefined' &&
        params['app_mode'] != undefined
          ? true
          : false;
      this.appModeValue = '>>' + params['app_mode'] + '<<';
    });

    console.log('game id', this.gameId, this.freeGame);
    this.dataService.getGame(this.gameId, this.freeGame).subscribe({
      next: (response) => {
        console.log('response??');
        this.singleGame = response;
        this.backRoute = this.singleGame.free ? '/free-games' : '/coach-games';
        this.gameBackground = this.singleGame.background;
        this.headerContent = this.singleGame.title;
        this.headerImage = this.singleGame.titleImage;
        this.gameContent = this.singleGame.content; //" APP MODE: " + this.appMode + "("+ this.appModeValue +")" +
        //this.gameContent =  " APP MODE: " + this.appMode + "("+ this.appModeValue +")" + this.singleGame.content ; //
        this.freeGame = this.singleGame.freeGame;
        this.gameModals = this.singleGame.modals;
        console.log('>>>>>>>>>>>> MODALS ', this.gameModals);
      },
      error: (err) => {
        //redirect to 404
        console.log(err);
        this.router.navigate(['404']);
      },
    });
  }
}
